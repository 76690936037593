import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class BloodGroupEnum {

    public static readonly PA: Data = { id: 1, name: "lblPositiveA", label: "+A" };
    public static readonly PAB: Data = { id: 2, name: "lblPositiveAB", label: "+AB" };
    public static readonly PB: Data = { id: 3, name: "lblPositiveB", label: "+B" };
    public static readonly PO: Data = { id: 4, name: "lblPositiveO", label: "+O" };
    public static readonly NA: Data = { id: 5, name: "lblNegativeA", label: "-A" };
    public static readonly NAB: Data = { id: 6, name: "lblNegativeAB", label: "-AB" };
    public static readonly NB: Data = { id: 7, name: "lblNegativeB", label: "-B" };
    public static readonly NO: Data = { id: 8, name: "lblNegativeO", label: "-O" };

    public static getBloodGroupList() {
        return [BloodGroupEnum.PA, BloodGroupEnum.PAB, BloodGroupEnum.PB, BloodGroupEnum.PO,
        BloodGroupEnum.NA, BloodGroupEnum.NAB, BloodGroupEnum.NB, BloodGroupEnum.NO];
    }

}
