import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class ExpectedDeliveryModeEnum {

    public static readonly CESAREAN: Data = { id: 1, name: "lblCesarean" };
    public static readonly VAGIANL: Data = { id: 2, name: "lblVaginalBirth" };

    public static getExpectedDeliveryModeList() {
        return [ExpectedDeliveryModeEnum.CESAREAN, ExpectedDeliveryModeEnum.VAGIANL];
    }

}
