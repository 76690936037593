import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class BiologyInterpretationEnum {

    public static readonly LOW: Data = { id: 1, name: "lblLow" };
    public static readonly NORMAL: Data = { id: 2, name: "lblNormal" };
    public static readonly HIGH: Data = { id: 3, name: "lblHigh" };

    public static getBiologyInterpretationList() {
        return [BiologyInterpretationEnum.LOW, BiologyInterpretationEnum.NORMAL, BiologyInterpretationEnum.HIGH];
    }

}
