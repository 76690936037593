import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class MorphologyStandardEnum {

    public static readonly DAVID: Data = { id: 1, name: "lblDavid" };
    public static readonly KRUGER: Data = { id: 2, name: "lblKruger" };

    public static getMorphologyStandardList() {
        return [MorphologyStandardEnum.DAVID, MorphologyStandardEnum.KRUGER];
    }

}
