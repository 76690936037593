import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class InfertilityTypeEnum {

    public static readonly PRIMARY: Data = { id: 1, name: "lblPrimary" };
    public static readonly SECONDARY: Data = { id: 2, name: "lblSecondary" };
    public static readonly RECURRANT_PREGNANCY_LOSS: Data = { id: 3, name: "lblRecurrantPregnancyLoss" };

    public static getInfertilityTypeList() {
        return [InfertilityTypeEnum.PRIMARY, InfertilityTypeEnum.SECONDARY, InfertilityTypeEnum.RECURRANT_PREGNANCY_LOSS];
    }

}
