import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class LifeStyleValidationEnum {

    public static readonly YES: Data = { id: 1, name: "lblYes" };
    public static readonly NO: Data = { id: 2, name: "lblNo" };
    public static readonly WEANED : Data = { id: 3, name: "lblWEANED" };
    public  static readonly NA : Data = { id: 4, name: "lblNA"}
    

    public static getLifeStyleValidationList() {
        return [LifeStyleValidationEnum.YES, LifeStyleValidationEnum.NO, LifeStyleValidationEnum.WEANED,
        LifeStyleValidationEnum.NA];
    }

}
