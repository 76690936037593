import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class PlacentaEnum {

    public static readonly ANTERIOR: Data = { id: 1, name: "lblAnterior" };
    public static readonly POSTERIOR: Data = { id: 2, name: "lblPosterior" };
    public static readonly FUNDAL: Data = { id: 3, name: "lblFundal" };
    public static readonly ANTEROFUNDAL: Data = { id: 4, name: "lblAnteroFundal" };
    public static readonly POSTEROFUNDAL: Data = { id: 5, name: "lblPosteroFundal" };

    public static getPlacentaList() {
        return [PlacentaEnum.ANTERIOR, PlacentaEnum.POSTERIOR, PlacentaEnum.FUNDAL,
                PlacentaEnum.ANTEROFUNDAL, PlacentaEnum.POSTEROFUNDAL];
    }

}
