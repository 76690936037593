import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class AmnioticFluidEnum {

    public static readonly NORMAL: Data = { id: 1, name: "lblNormal" };
    public static readonly OLIGOAMNIOS: Data = { id: 2, name: "lblOligoamnios" };
    public static readonly HYDRAMNIOS: Data = { id: 3, name: "lblHydramnios" };

    public static getAmnioticFluidList() {
        return [AmnioticFluidEnum.NORMAL, AmnioticFluidEnum.OLIGOAMNIOS, AmnioticFluidEnum.HYDRAMNIOS];
    }

}
