import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class ResultEnum {

    public static readonly SUCCESS: Data = { id: 1, name: "lblSuccess" };
    public static readonly FAILED: Data = { id: 2, name: "lblFailed" };
    public static readonly UNKNOWN: Data = { id: 3, name: "lblUnknown" };

    public static getResultList() {
        return [ResultEnum.SUCCESS, ResultEnum.FAILED, ResultEnum.UNKNOWN];
    }

}
