import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AppStartupHttpService {

    constructor( private httpClient: HttpClient ) { }

    /**
     * get the User informations
     */
    getUserInfos() {
        return this.httpClient.get<any>( '/getUserInfos' );
    }
    
    /**
     * get the list of authorities
     */
    getAuthorityList() {
        return this.httpClient.get<any>( '/getAuthorityList' );
    }

}
