import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class ConceptionTypeEnum {

    public static readonly NATURAL: Data = { id: 1, name: "lblNatural" };
    public static readonly OVARIANSTIMULATION: Data = { id: 2, name: "lblOvarianStimulation" };
    public static readonly INTRAUTERINEINSEMINATION: Data = { id: 3, name: "lblIntrauterineInsemination" };
    public static readonly IVF: Data = { id: 4, name: "lblIVF" };

    public static getConceptionTypeList() {
        return [ConceptionTypeEnum.NATURAL, ConceptionTypeEnum.OVARIANSTIMULATION,
                ConceptionTypeEnum.INTRAUTERINEINSEMINATION, ConceptionTypeEnum.IVF];
    }

}
