
import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class UtrasoundRouteEnum {

    public static readonly ABDOMINALE: Data = { id: 1, name: "lblTransAbdominal" };
    public static readonly VAGINALE: Data = { id: 2, name: "lblTransVaginal" };
    public static readonly ABDOVAGINALE: Data = { id: 3, name: "lblTransAbdominalVaginal" };

	public static getUtrasoundRouteList() {
		return [UtrasoundRouteEnum.ABDOMINALE, UtrasoundRouteEnum.VAGINALE,
		UtrasoundRouteEnum.ABDOVAGINALE];
	}

}