import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class OvaryEnum {

    public static readonly NORMAL: Data = { id: 1, name: "lblNormal" };
    public static readonly NOTEXAMINED: Data = { id: 2, name: "lblNotExamined" };
    public static readonly CYST: Data = { id: 3, name: "lblCyst" };
    public static readonly FOLLICLE: Data = { id: 4, name: "lblFollicle" };
    public static readonly PCOS: Data = { id: 5, name: "lblPCOS" };

    public static getOvaryList() {
        return [OvaryEnum.NORMAL, OvaryEnum.NOTEXAMINED, OvaryEnum.CYST,
                OvaryEnum.FOLLICLE, OvaryEnum.PCOS];
    }

}
