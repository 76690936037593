import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class LocationEnum {

    public static readonly ANTERIOR: Data = { id: 1, name: "lblAnterior" };
    public static readonly POSTERIOR: Data = { id: 2, name: "lblPosterior" };
    public static readonly FUNDAL: Data = { id: 3, name: "lblFundal" };
    public static readonly ISTHMIC: Data = { id: 4, name: "lblIsthmic" };
    public static readonly CERVICAL: Data = { id: 5, name: "lblCervical" };
    public static readonly BROADLIGAMENT: Data = { id: 6, name: "lblBroadLigament" };

    public static getLocationList() {
        return [LocationEnum.ANTERIOR, LocationEnum.POSTERIOR, LocationEnum.FUNDAL,
                LocationEnum.ISTHMIC, LocationEnum.CERVICAL, LocationEnum.BROADLIGAMENT];
    }

}
