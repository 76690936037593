import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class TrimesterEnum {

    public static readonly FIRSTTRIMESTER: Data = { id: 1, name: "lblFirstTrimester" };
    public static readonly SECONDTRIMESTER: Data = { id: 2, name: "lblSecondTrimester" };

    public static getTrimesterList() {
        return [TrimesterEnum.FIRSTTRIMESTER, TrimesterEnum.SECONDTRIMESTER];
    }

}
