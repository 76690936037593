import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class EventEnum {

	public static readonly PONCTION: Data = { id: 1, name: "lblPonction" };
    public static readonly TEC: Data = { id: 2, name: "lblFET" };
	public static readonly RAPPORT: Data = { id: 3, name: "lblIntercourse" };
	public static readonly MONITORAGE: Data = { id: 4, name: "lblMonitoring" };
	public static readonly IUI: Data = { id: 5, name: "lblIui" };

	public static getEventList(){
		return [EventEnum.PONCTION,EventEnum.TEC,EventEnum.RAPPORT, EventEnum.MONITORAGE, EventEnum.IUI];
	}

}