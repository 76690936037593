import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class PregnancyGlycemicCycleUnitEnum {

    public static readonly UNITGL: Data = { id: 1, name: "lblUnitGL" };
    public static readonly UNITMMOLL: Data = { id: 2, name: "lblUnitMmolL" };

    public static getPregnancyGlycemicCycleUnitList() {
        return [PregnancyGlycemicCycleUnitEnum.UNITGL, PregnancyGlycemicCycleUnitEnum.UNITMMOLL];
    }

}
