import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class DouglasEnum {

    public static readonly EMPTY: Data = { id: 1, name: "lblEmpty" };
    public static readonly ABNORMAL: Data = { id: 2, name: "lblAbnormal" };
    public static readonly FLUID: Data = { id: 3, name: "lblFluid" };

    public static getDouglasList() {
        return [DouglasEnum.EMPTY, DouglasEnum.ABNORMAL, DouglasEnum.FLUID];
    }

}
