import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class SignEnum {

    public static readonly POSITIVE: Data = { id: 1, name: "lblPositive" };
    public static readonly NEGATIVE: Data = { id: 2, name: "lblNegative" };

    public static getSignList() {
        return [SignEnum.POSITIVE, SignEnum.NEGATIVE];
    }

}
