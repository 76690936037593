import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class SurgicalApproachEnum {

    public static readonly LAPAROTOMY: Data = { id: 1, name: "lblLaparotomy" };
    public static readonly LAPAROSCOPY: Data = { id: 2, name: "lblLaparoscopy" };
    public static readonly HYSTEROSCOPY: Data = { id: 3, name: "lblHysteroscopy" };
    public static readonly VAGINALSURGERY: Data = { id: 4, name: "lblVaginalSurgery" };
    public static readonly OTHER: Data = { id: 5, name: "lblOther" };

    public static getSurgicalApproachList() {
        return [SurgicalApproachEnum.LAPAROTOMY, SurgicalApproachEnum.LAPAROSCOPY,
                SurgicalApproachEnum.HYSTEROSCOPY, SurgicalApproachEnum.VAGINALSURGERY,
                SurgicalApproachEnum.OTHER];
    }

}
