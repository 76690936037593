import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class PregnancyUltrasoundEarlyHeartEnum {

    public static readonly POSITIVE: Data = { id: 1, name: "lblPositive" };
    public static readonly NEGATIVE: Data = { id: 2, name: "lblNegative" };
    public static readonly DOUBTFUL: Data = { id: 3, name: "lblDoubtful" };

    public static getPregnancyUltrasoundEarlyHeartList() {
        return [PregnancyUltrasoundEarlyHeartEnum.POSITIVE, PregnancyUltrasoundEarlyHeartEnum.NEGATIVE, 
                PregnancyUltrasoundEarlyHeartEnum.DOUBTFUL];
    }

}
