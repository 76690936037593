import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class TransferEmbryoDayEnum {

    public static readonly FRESH: Data = { id: 1, name: "lblFresh" };
    public static readonly FROZEN: Data = { id: 2, name: "lblFrozen" };

    public static getTransferEmbryoDayList() {
        return [TransferEmbryoDayEnum.FRESH, TransferEmbryoDayEnum.FROZEN];
    }

}
