import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class StatusEnum {

    public static readonly NORMAL: Data = { id: 1, name: "lblNormal" };
    public static readonly ABNORMAL: Data = { id: 2, name: "lblAbnormal" };

    public static getStatusList() {
        return [StatusEnum.NORMAL, StatusEnum.ABNORMAL];
    }

}
