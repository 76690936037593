import { NgModule } from '@angular/core';
import { DataSharedService } from './data-shared.service';
import {DataMedicalRecordExportService} from "./data-medical-record-export";
import { DateUtcTimezonePipe } from 'app/app-web/app-core/date/date-utc-timezone.pipe';
import { DateService } from 'app/app-web/app-core/date/date.service';


@NgModule( {
    declarations: [],
    imports: [],
    providers: [
        DataSharedService,
        DataMedicalRecordExportService,
        DateUtcTimezonePipe,
        DateService
    ],

} )
export class DataSharedModule { }
