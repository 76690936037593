import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class OutcomeEnum {

    public static readonly DELIVERY: Data = { id: 1, name: "lblDelivery", 
		dataList: [{ id: 1, name: "lblVaginalBirth" }, { id: 2, name: "lblCSection" }] };
    public static readonly MISCARRIAGE: Data = { id: 2, name: "lblMiscarriage", 
		dataList: [{ id: 3, name: "lblDilatationAndCurettage" }, { id: 4, name: "lblMedical" }] };
    public static readonly MISSEDMISCARRIAGE: Data = { id: 3, name: "lblMissedMiscarriage", 
		dataList: [{ id: 3, name: "lblDilatationAndCurettage" }, { id: 4, name: "lblMedical" }] };
    public static readonly LATEABORTION: Data = { id: 4, name: "lblLateAbortion", 
		dataList: [{ id: 1, name: "lblVaginalBirth" }, { id: 2, name: "lblCSection" }] };
    public static readonly ECTOPICPREGNANCY: Data = { id: 5, name: "lblEctopicPregnancy", 
		dataList: [{ id: 4, name: "lblMedical" }, { id: 5, name: "lblSalpingectomy" }, { id: 6, name: "lblSalpingotomy" }] };
    public static readonly ELECTIVEABORTION: Data = { id: 6, name: "lblElectiveAbortion", 
		dataList: [{ id: 3, name: "lblDilatationAndCurettage" }, { id: 4, name: "lblMedical" }] };
    public static readonly MEDICALTERMINATIONOFPREGNANCY: Data = { id: 7, name: "lblMedicalTerminationOfPregnancy", 
		dataList: [{ id: 1, name: "lblVaginalBirth" }, { id: 2, name: "lblCSection" }, { id: 3, name: "lblDilatationAndCurettage" }] };

    public static getOutcomeList() {
        return [OutcomeEnum.DELIVERY, OutcomeEnum.MISCARRIAGE,
                OutcomeEnum.MISSEDMISCARRIAGE, OutcomeEnum.LATEABORTION,
                OutcomeEnum.ECTOPICPREGNANCY, OutcomeEnum.ELECTIVEABORTION,
                OutcomeEnum.MEDICALTERMINATIONOFPREGNANCY];
    }

}
