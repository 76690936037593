import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class CivilStatusEnum {

    public static readonly SINGLE: Data = { id: 1, name: "lblSingle" };
    public static readonly MARRIED: Data = { id: 2, name: "lblMarried" };
    public static readonly WIDOWER: Data = { id: 3, name: "lblWidower" };
    public static readonly DIVORCED: Data = { id: 4, name: "lblDivorced" };
    public static readonly SEPARATED: Data = { id: 5, name: "lblSeparated" };
    public static readonly COHABITATION: Data = { id: 6, name: "lblCohabitation" };

    public static getCivilStatusList() {
        return [CivilStatusEnum.SINGLE, CivilStatusEnum.MARRIED, CivilStatusEnum.WIDOWER,
					CivilStatusEnum.DIVORCED, CivilStatusEnum.SEPARATED, CivilStatusEnum.COHABITATION];
    }

}
