import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class ValidationEnum {

    public static readonly YES: Data = { id: 1, name: "lblYes" };
    public static readonly NO: Data = { id: 2, name: "lblNo" };

    public static getValidationList() {
        return [ValidationEnum.YES, ValidationEnum.NO];
    }

}
