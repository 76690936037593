import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class BacteriologyInterpretationEnum {

    public static readonly POSITIVE: Data = { id: 1, name: "lblPositive" };
    public static readonly NEGATIVE: Data = { id: 2, name: "lblNegative" };
    public static readonly CONTAMINATED: Data = { id: 3, name: "lblContaminated" };

    public static getBacteriologyInterpretationList() {
        return [BacteriologyInterpretationEnum.POSITIVE, BacteriologyInterpretationEnum.NEGATIVE, 
                BacteriologyInterpretationEnum.CONTAMINATED];
    }

}
