import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class SexEnum {

    public static readonly MALE: Data = { id: 1, name: "lblMale" };
    public static readonly FEMALE: Data = { id: 2, name: "lblFemale" };
    public static readonly UNDEFINED: Data = { id: 3, name: "lblUndefined" };

    public static getSexList() {
        return [SexEnum.MALE, SexEnum.FEMALE, SexEnum.UNDEFINED];
    }

}
