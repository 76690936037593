import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class DegreeEnum {

    public static readonly INFERIOR3: Data = { id: 1, name: "lblInferior3" };
    public static readonly INFERIOR5: Data = { id: 2, name: "lblInferior5" };
    public static readonly BETWEEN5AND10: Data = { id: 3, name: "lblBetween5And10" };
    public static readonly EQUAL50: Data = { id: 4, name: "lblEqual50" };
    public static readonly BETWEEN50AND90: Data = { id: 5, name: "lblBetween50And90" };
    public static readonly EQUAL90: Data = { id: 6, name: "lblEqual90" };
    public static readonly SUPERIOR90: Data = { id: 7, name: "lblSuperior90" };
    public static readonly SUPERIOR95: Data = { id: 8, name: "lblSuperior95" };
    public static readonly BETWEEN10AND50: Data = { id: 9, name: "lblBetween10And50" };
    public static readonly EQUAL10: Data = { id: 10, name: "lblEqual10" };

    public static getDegreeList() {
        return [DegreeEnum.INFERIOR3, DegreeEnum.INFERIOR5, DegreeEnum.BETWEEN5AND10, DegreeEnum.EQUAL10, DegreeEnum.BETWEEN10AND50, DegreeEnum.EQUAL50,
                DegreeEnum.BETWEEN50AND90, DegreeEnum.EQUAL90, DegreeEnum.SUPERIOR90, DegreeEnum.SUPERIOR95];
    }

}
