import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class PresentationEnum {

    public static readonly CEPHALIC: Data = { id: 1, name: "lblCephalic" };
    public static readonly BREECH: Data = { id: 2, name: "lblBreech" };
    public static readonly TRANSVERSE: Data = { id: 3, name: "lblTransverse" };
    public static readonly OBLIQUE: Data = { id: 4, name: "lblOblique" };

    public static getPresentationList() {
        return [PresentationEnum.CEPHALIC, 
                PresentationEnum.BREECH,
                PresentationEnum.TRANSVERSE, 
                PresentationEnum.OBLIQUE];
    }

}
