import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class AppearanceEnum {

    public static readonly USUALAPPEARANCE: Data = { id: 1, name: "lblUsualAppearance" };
    public static readonly UNUSUALAPPEARANCE: Data = { id: 2, name: "lblUnusualAppearance" };
    public static readonly NOTEXAMINED: Data = { id: 3, name: "lblNotExamined" };

    public static getAppearanceList() {
        return [AppearanceEnum.USUALAPPEARANCE, AppearanceEnum.UNUSUALAPPEARANCE, AppearanceEnum.NOTEXAMINED];
    }

}
