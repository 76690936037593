import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class DeliveryModeEnum {

    public static readonly CESAREAN: Data = { id: 1, name: "lblCesarean" };
    public static readonly INSTRUMENTAL: Data = { id: 2, name: "lblInstrumental" };
    public static readonly VAGIANL: Data = { id: 3, name: "lblVagianl" };

    public static getDeliveryModeList() {
        return [DeliveryModeEnum.CESAREAN, DeliveryModeEnum.INSTRUMENTAL, DeliveryModeEnum.VAGIANL];
    }

}
