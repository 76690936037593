import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class MyometreEnum {

    public static readonly NORMAL: Data = { id: 1, name: "lblNormal" };
    public static readonly MYOMA: Data = { id: 2, name: "lblMyoma" };
    public static readonly ADENOMYOSIS: Data = { id: 3, name: "lblAdenomyosis" };

    public static getMyometreList() {
        return [MyometreEnum.NORMAL, MyometreEnum.MYOMA, MyometreEnum.ADENOMYOSIS];
    }

}
