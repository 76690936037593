import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class BhcgEnum {

	public static readonly POSITIVE: Data = { id: 1, name: "lblPositive" };
    public static readonly NEGATIVE: Data = { id: 2, name: "lblNegative" };
	public static readonly UNKNOWEN: Data = { id: 3, name: "lblUnknowen" };
	
	public static getBhcgList(){
		return [BhcgEnum.POSITIVE,BhcgEnum.NEGATIVE,BhcgEnum.UNKNOWEN];
	}

}