import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class UterusSizeEnum {

    public static readonly NORMAL: Data = { id: 1, name: "lblNormal" };
    public static readonly ENLARGED: Data = { id: 2, name: "lblEnlarged" };
    public static readonly HYPOPLASIA: Data = { id: 3, name: "lblHypoplasia" };

    public static getUterusSizeList() {
        return [UterusSizeEnum.NORMAL, UterusSizeEnum.ENLARGED, UterusSizeEnum.HYPOPLASIA];
    }

}
