import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class MenstrualCycleRegularityEnum {

    public static readonly REGULAR: Data = { id: 1, name: "lblRegular" };
    public static readonly IRREGULAR: Data = { id: 2, name: "lblIrregular" };

    public static getMenstrualCycleRegularityList() {
        return [MenstrualCycleRegularityEnum.REGULAR, MenstrualCycleRegularityEnum.IRREGULAR];
    }

}
