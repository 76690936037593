import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class SerologyInterpretationEnum {

    public static readonly POSITIVE: Data = { id: 1, name: "lblPositive" };
    public static readonly NEGATIVE: Data = { id: 2, name: "lblNegative" };
    public static readonly EQUIVOCAL: Data = { id: 3, name: "lblEquivocal" };

    public static getSerologyInterpretationList() {
        return [SerologyInterpretationEnum.POSITIVE, SerologyInterpretationEnum.NEGATIVE, SerologyInterpretationEnum.EQUIVOCAL];
    }

}
