import { Data } from "app/app-web/app-model/utils/dto/data.model";

export class DrugFormEnum {

    public static readonly PILL: Data = { id: 1, name: "lblPill" };
    public static readonly CAPSULE: Data = { id: 2, name: "lblCapsule" };
    public static readonly SYRUP: Data = { id: 3, name: "lblSyrup" };
    public static readonly AEROSOL: Data = { id: 4, name: "lblAerosol" };
    public static readonly LIQUID_INJECTION: Data = { id: 5, name: "lblLiquidInjection" };
    public static readonly POWDER: Data = { id: 6, name: "lblPowder" };
    public static readonly OTHER: Data = { id: 7, name: "lblOther" };
    public static readonly OINTMENT: Data = { id: 8, name: "lblOintment" };
    public static readonly SUPPOSITORY: Data = { id: 9, name: "lblSuppository" };
    public static readonly CREAM: Data = { id: 10, name: "lblCream" };
    public static readonly VAGINAL_OVULE: Data = { id: 11, name: "lblVaginalOvule" };
    public static readonly MILK: Data = { id: 12, name: "lblMilk" };
    public static readonly GEL: Data = { id: 13, name: "lblGel" };
    public static readonly RECTAL_ENEMA: Data = { id: 14, name: "lblRectalEnema" };
    public static readonly VAGINAL_ENEMA: Data = { id: 15, name: "lblVaginalEnema" };
    public static readonly SPRAY: Data = { id: 16, name: "lblSpray" };
    public static readonly DROP: Data = { id: 17, name: "lblDrop" };
    public static readonly SACHET: Data = { id: 18, name: "lblSachet" };

    public static getDrugFormList() {
        return [DrugFormEnum.PILL, DrugFormEnum.CAPSULE, DrugFormEnum.SYRUP, DrugFormEnum.AEROSOL,
                DrugFormEnum.LIQUID_INJECTION, DrugFormEnum.POWDER,
                DrugFormEnum.OINTMENT, DrugFormEnum.SUPPOSITORY, DrugFormEnum.CREAM,
                DrugFormEnum.VAGINAL_OVULE, DrugFormEnum.MILK, DrugFormEnum.GEL,
                DrugFormEnum.RECTAL_ENEMA, DrugFormEnum.VAGINAL_ENEMA, DrugFormEnum.SPRAY, DrugFormEnum.DROP, DrugFormEnum.SACHET,
				DrugFormEnum.OTHER];
    }

}
